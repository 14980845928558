import React from "react"
import { graphql } from "gatsby"
import Site from "../components/Site"
import TalksPlaylist from "../components/TalksPlaylist"
import TalksRecent from "../components/TalksRecent"
import { formatLink } from '../helpers'
import "../css/talks.scss"

const Speaker = ({data}) => {
  const theSpeaker         = data.datoCmsPerson
  const allTalks           = [...data.allDatoCmsTalk.edges].map(el => el.node)
  const allFromSameSpeaker = [...allTalks].filter(aTalk => aTalk.speaker.slug === theSpeaker.slug)

  return (
    <Site
      title      = {`${theSpeaker.name} | Talks`}
      className  = "hcc-talks hcc-talks-speaker"
      themeColor = "#141414"
    >
      <article>
        <TalksPlaylist
          data  = {allFromSameSpeaker}
          title = {<React.Fragment><span>All Talks by</span> <strong>{theSpeaker.name}</strong></React.Fragment>}
        />
        <TalksRecent
          data   = {allTalks}
          title  = "More Talks"
          offset = {0}
          count  = {10}
          more   = {formatLink('', 't')}
        />
      </article>
    </Site>
  )
}

export default Speaker

export const query = graphql`
  query PersonQuerySingle($slug : String!) {
    datoCmsPerson(slug: {eq : $slug}) {
      ...PersonFragment
    }
    allDatoCmsTalk(
      sort : {fields : date, order : DESC}
    ) {
      edges {
        node {
          ...TalkFragment
        }
      }
    }
  }
`
